.App {
  text-align: center;
  background-color: #282c34;
  font-size: calc(6px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
}
.App-logo {
  height: 40vmin;
}
.App-link {
  color: #09d3ac;
}
.App-footer {
  font-size: calc(5px + 1vmin);
}

h2 {
  font-size: calc(10px + 2vmin);
}
h3 {
  font-style: italic;
  font-size: calc(8px + 2vmin);
  text-align: center;
}
.MainText {
  font-size: calc(15px + 2vmin);
}


.HorizontalContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Education{
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.ListCard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  text-align: left;
  margin: 1%;
  padding: 1%;
}
.School{
  flex-grow: 1;
  flex-basis: 0;
}


